import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { FaPhone, FaPencilAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md'

import Layout from "../components/layout"
import SEO from "../components/seo"

import PageTitle from "../components/pageTitle"

import * as contactStyles from "../styles/components/contact.module.sass"
import { Link } from 'gatsby' 

import cx from 'classnames'

const ContactPage = () => (

  <Layout pageInfo={{ pageName: "Contact" }}>
    <SEO title="Contact" />
    <PageTitle title="Contact Us" />

    <Container className="blueBox">
      <Row>
        <Col className="text-center">
          Please phone or email if you have any questions; we would love to hear from you.
        </Col>
      </Row>
      <Row>
        <Col md="4" sm="12">
          <Row>
            <Col className={cx("justify-content-center, text-center", contactStyles.icon)}>
              <FaPhone className="align-middle" />
            </Col>
          </Row>
          <Row>
            <Col className={cx("justify-content-center, text-center")}>
              <p>
                <a href="tel:00447941313141">07941 313141</a>
                <br />
                or
                <br />
                <a href="tel:00441803472930">01803 472930</a>
              </p>
            </Col>
          </Row>
        </Col>
        <Col md="4" sm="12">
          <Row>
            <Col className={cx("justify-content-center, text-center", contactStyles.icon)}>
              <MdEmail className="align-middle" />
            </Col>
          </Row>
          <Row>
            <Col className={cx("justify-content-center, text-center")}>
              <a href="mailto:enquiries@devonwindmills.co.uk">enquiries@devonwindmills.co.uk</a>
            </Col>
          </Row>
        </Col>
        <Col md="4" sm="12">
          <Row>
            <Col className={cx("justify-content-center, text-center", contactStyles.icon)}>
              <FaPencilAlt className="align-middle" />
            </Col>
          </Row>
          <Row>
            <Col className={cx("justify-content-center", "text-center")}>
              <p>Devon Windmill Holidays<br />Yattors, Dainton<br />Near Newton Abbot<br />Devon, TQ12 5TZ</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <p>
            Please note, this is our home address for correspondence, not that of the windmill. The mill is three miles away from us; see <Link to="/location">Location page</Link>.
        </p>
        </Col>
      </Row>
    </Container>

  </Layout>
)

export default ContactPage
