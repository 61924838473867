// extracted by mini-css-extract-plugin
export var cyan = "contact-module--cyan--2YfPd";
export var cyanBg = "contact-module--cyan-bg--1P_F9";
export var cyanBo = "contact-module--cyan-bo--2kWht";
export var androidGreen = "contact-module--androidGreen--1r7z4";
export var androidGreenBg = "contact-module--androidGreen-bg--1wvXC";
export var androidGreenBo = "contact-module--androidGreen-bo--Jc5R2";
export var lavender = "contact-module--lavender--P5XL7";
export var lavenderBg = "contact-module--lavender-bg--2J6Yz";
export var lavenderBo = "contact-module--lavender-bo--1E9x8";
export var corn = "contact-module--corn--1BbUa";
export var cornBg = "contact-module--corn-bg--3DDlI";
export var cornBo = "contact-module--corn-bo--3WZ96";
export var trns = "contact-module--trns--24-l9";
export var icon = "contact-module--icon--bVXNb";