// extracted by mini-css-extract-plugin
export var cyan = "page-module--cyan--208uY";
export var cyanBg = "page-module--cyan-bg--35ltw";
export var cyanBo = "page-module--cyan-bo--f-bpa";
export var androidGreen = "page-module--androidGreen--1MEZl";
export var androidGreenBg = "page-module--androidGreen-bg--ZtuzP";
export var androidGreenBo = "page-module--androidGreen-bo--2uDbq";
export var lavender = "page-module--lavender--YoICE";
export var lavenderBg = "page-module--lavender-bg--3BxWQ";
export var lavenderBo = "page-module--lavender-bo--3ND2X";
export var corn = "page-module--corn--2EfBQ";
export var cornBg = "page-module--corn-bg--3QtMY";
export var cornBo = "page-module--corn-bo--3h8PI";
export var trns = "page-module--trns--1Mvre";
export var title = "page-module--title--35-PM";