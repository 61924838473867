import React from "react"
import PropTypes from 'prop-types';

import { Container, Row, Col } from "react-bootstrap"

import * as pageStyles from "../styles/components/page.module.sass"

const PageTitle = (props) => (
    <Container >
        <Row className="justify-content-md-center">
            <Col className="text-center">
                <h1 className={pageStyles.title}>{props.title}</h1>
            </Col>
        </Row>
    </Container>
)

PageTitle.propTypes = { title: PropTypes.string.isRequired }

export default PageTitle
